import React from "react";
import SEO from "../components/Seo";
import PageTitle from "../components/PageTitle";
import Layout from "../components/Layout";
import ProjectsList from "../components/ProjectsList";
import Container from "@material-ui/core/Container";

const ProjectsPage = () => {
  return (
    <Layout>
      <SEO title="Projects" />
      <PageTitle bottomPadding>Projects</PageTitle>
      <Container>
        <ProjectsList />
      </Container>
    </Layout>
  );
};

export default ProjectsPage;
