import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Grid, Typography } from "@material-ui/core";
import Fade from "react-reveal/Fade";
import { makeStyles } from "@material-ui/styles";
import Img from "gatsby-image";
import Chip from "@material-ui/core/Chip";
import Fab from "@material-ui/core/Fab";
import Link from "./Link";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4)
  },
  project: {
    display: "flex",
    maxWidth: 900,
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 auto"
  },
  img: {
    maxWidth: 600,
    margin: "0 auto",
    height: "auto",
    borderRadius: theme.borderRadius,
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      width: "100%",
      marginBottom: theme.spacing(4)
    }
  },
  chips: {
    overflowX: "auto",
    width: "100%",
    display: "flex",
    flexWrap: "nowrap"
  },
  chip: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  container: {
    width: "100%",
    "@media screen and (min-width: 600px)": {
      width: 600
    },
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginBottom: theme.spacing(20),
    marginTop: theme.spacing(-4),
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(4),
      marginBottom: theme.spacing(4)
    }
  },
  gridContainer: {
    display: "flex",
    justifyContent: "center"
  },

  readBtn: {
    marginTop: theme.spacing(2)
  }
}));

export default function ProjectsList() {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      contentfulProjectList(name: { eq: "AllProjects" }) {
        projects {
          id
          title
          slug
          summary
          techs {
            name
          }
          thumbnail {
            fluid(maxWidth: 600) {
              base64
              aspectRatio
              src
              srcSet
            }
          }
        }
      }
    }
  `);
  let projects = [];

  data.contentfulProjectList.projects.forEach(project => {
    projects.push(
      <Grid item lg={6} xs={12} key={project.id} className={classes.image}>
        <Link to={`/projects/${project.slug}`}>
          <Img
            className={classes.img}
            alt={project.title + "thumbnail"}
            fluid={project.thumbnail.fluid}
          />
        </Link>
      </Grid>
    );

    projects.push(
      <Grid item lg={6} xs={12} className={classes.gridContainer}>
        <section className={classes.container}>
          <Typography variant={"h4"} component={"h2"} color={"textPrimary"}>
            {project.title}
          </Typography>
          <Typography variant={"subtitle1"}>{project.summary}</Typography>
          <div className={classes.chips}>
            {project.techs.map((tech, index) => (
              <Chip className={classes.chip} label={tech.name} key={index} />
            ))}
          </div>

          <Fab
            variant="extended"
            className={classes.readBtn}
            color="primary"
            component={Link}
            to={`/projects/${project.slug}`}
          >
            Read More
          </Fab>
        </section>
      </Grid>
    );
  });

  return (
    <Fade up duration={300}>
      <Grid className={classes.root} container>
        {projects}
      </Grid>
    </Fade>
  );
}
