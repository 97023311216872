import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Container, Typography } from "@material-ui/core";
import waves from "../imgs/waves_2.svg";

const useStyles = makeStyles(theme => ({
  bg: {
    backgroundColor: theme.palette.primary.dark,
    paddingTop: theme.spacing(8),
    color: "white"
  },
  bottom: {
    width: "100%",
    height: theme.spacing(16),
    position: "relative",
    zIndex: -1
  }
}));
export default function PageTitle({ children, bottomPadding }) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.bg}>
        <Container>
          <Typography variant="h1" align="center" color="inherit">
            {children}
          </Typography>
        </Container>
      </div>
      <img className={classes.bottom} alt="" src={waves} />
    </div>
  );
}

PageTitle.propTypes = {
  children: PropTypes.string.isRequired
};
